import { t } from '~/helpers/localization';
import { ICategories, IResponse } from '~/interfaces';
import { NAME_BACKGROUND_FILTER, NAME_PATCH_FILTER, NAME_SYMBOL_FILTER } from '~/Actions/ActionFilter';
import { getLevelFromRomanFormat } from '~/helpers/levels';
import { store } from '~/app';
import { CREW_TYPES, CREWS_CATEGORY_TYPES, ENSIGNS_CATEGORY_TYPES, TYPE_ALL, TYPE_IS_HAVE, TYPE_IS_NOT_HAVE } from '~/helpers/consts';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';

export type FILTERS_NAMES = 'display' | 'nation' | 'type_ship' | 'level_ship' | 'doll_category' | 'crew_category' | 'crew_type' | 'ensign_category';

export type FILTER_TYPES = 'radio' | 'checkbox';

export const DISPLAY_NAME_FILTER = 'display';
export const NATION_NAME_FILTER = 'nation';
export const TYPE_SHIP_NAME_FILTER = 'type_ship';
export const LEVEL_SHIP_NAME_FILTER = 'level_ship';
export const DOLL_CATEGORY_NAME_FILTER = 'doll_category';
export const CREW_CATEGORY_NAME_FILTER = 'crew_category';
export const CREW_TYPE_NAME_FILTER = 'crew_type';
export const ENSIGN_CATEGORY_NAME_FILTER = 'ensign_category';

export const FILTER_RADIO_TYPE = 'radio';
export const FILTER_CHECKBOX_TYPE = 'checkbox';

export interface IFilterValues {
    title: string | number;
    value: string | number;
    icons?: any;
}

export interface IMaps {
    name: FILTERS_NAMES;
    title: string;
    type: FILTER_TYPES;
    categories?: ICategories[] | null;
    values: IFilterValues[];
    defaultValues: string[];
}

interface IDepFilters {
    vehicles: {
        nation: string[];
        type_ship: string[];
        level_ship: string[];
    };
    crews: {
        nation: string[];
        crew_category: string[];
        crew_type: string[];
    };
    permoflages: {
        nation: string[];
        type_ship: string[];
        level_ship: string[];
    };
    ensigns: {
        ensign_category: string[];
    };
    dogTagComponents: {
        doll_category: string[];
    };
}

export const categoryFiltersDependency: IDepFilters = {
    [VEHICLE_CATEGORY]: {
        [NATION_NAME_FILTER]: [TYPE_SHIP_NAME_FILTER, LEVEL_SHIP_NAME_FILTER],
        [TYPE_SHIP_NAME_FILTER]: [NATION_NAME_FILTER, LEVEL_SHIP_NAME_FILTER],
        [LEVEL_SHIP_NAME_FILTER]: [NATION_NAME_FILTER, TYPE_SHIP_NAME_FILTER],
    },
    [CREWS_CATEGORY]: {
        [NATION_NAME_FILTER]: [CREW_CATEGORY_NAME_FILTER, CREW_TYPE_NAME_FILTER],
        [CREW_CATEGORY_NAME_FILTER]: [NATION_NAME_FILTER, CREW_TYPE_NAME_FILTER],
        [CREW_TYPE_NAME_FILTER]: [NATION_NAME_FILTER, CREW_CATEGORY_NAME_FILTER],
    },
    [PERMOFLAGES_CATEGORY]: {
        [NATION_NAME_FILTER]: [TYPE_SHIP_NAME_FILTER, LEVEL_SHIP_NAME_FILTER],
        [TYPE_SHIP_NAME_FILTER]: [NATION_NAME_FILTER, LEVEL_SHIP_NAME_FILTER],
        [LEVEL_SHIP_NAME_FILTER]: [NATION_NAME_FILTER, TYPE_SHIP_NAME_FILTER],
    },
    [ENSIGNS_CATEGORY]: {
        [ENSIGN_CATEGORY_NAME_FILTER]: [],
    },
    [DOLLS_CATEGORY]: {
        [DOLL_CATEGORY_NAME_FILTER]: [],
    },
};

export const facetFiltersDependency = {
    [VEHICLE_CATEGORY]: {
        [NATION_NAME_FILTER]: {
            [TYPE_SHIP_NAME_FILTER]: LEVEL_SHIP_NAME_FILTER,
            [LEVEL_SHIP_NAME_FILTER]: TYPE_SHIP_NAME_FILTER,
        },
        [TYPE_SHIP_NAME_FILTER]: {
            [NATION_NAME_FILTER]: LEVEL_SHIP_NAME_FILTER,
            [LEVEL_SHIP_NAME_FILTER]: NATION_NAME_FILTER,
        },
        [LEVEL_SHIP_NAME_FILTER]: {
            [TYPE_SHIP_NAME_FILTER]: NATION_NAME_FILTER,
            [NATION_NAME_FILTER]: TYPE_SHIP_NAME_FILTER,
        },
    },
    [CREWS_CATEGORY]: {
        [NATION_NAME_FILTER]: {
            [CREW_CATEGORY_NAME_FILTER]: CREW_TYPE_NAME_FILTER,
            [CREW_TYPE_NAME_FILTER]: CREW_CATEGORY_NAME_FILTER,
        },
        [CREW_CATEGORY_NAME_FILTER]: {
            [NATION_NAME_FILTER]: CREW_TYPE_NAME_FILTER,
            [CREW_TYPE_NAME_FILTER]: NATION_NAME_FILTER,
        },
        [CREW_TYPE_NAME_FILTER]: {
            [NATION_NAME_FILTER]: CREW_CATEGORY_NAME_FILTER,
            [CREW_CATEGORY_NAME_FILTER]: NATION_NAME_FILTER,
        },
    },
    [PERMOFLAGES_CATEGORY]: {
        [NATION_NAME_FILTER]: {
            [TYPE_SHIP_NAME_FILTER]: LEVEL_SHIP_NAME_FILTER,
            [LEVEL_SHIP_NAME_FILTER]: TYPE_SHIP_NAME_FILTER,
        },
        [TYPE_SHIP_NAME_FILTER]: {
            [NATION_NAME_FILTER]: LEVEL_SHIP_NAME_FILTER,
            [LEVEL_SHIP_NAME_FILTER]: NATION_NAME_FILTER,
        },
        [LEVEL_SHIP_NAME_FILTER]: {
            [TYPE_SHIP_NAME_FILTER]: NATION_NAME_FILTER,
            [NATION_NAME_FILTER]: TYPE_SHIP_NAME_FILTER,
        },
    },
};

const filtersMap: IMaps[] = [
    {
        name: DISPLAY_NAME_FILTER,
        type: FILTER_RADIO_TYPE,
        title: t('Отображение'),
        values: [
            {
                title: t('Все'),
                value: TYPE_ALL,
            },
            {
                title: t('Полученные'),
                value: TYPE_IS_HAVE,
            },
            {
                title: t('Не полученные'),
                value: TYPE_IS_NOT_HAVE,
            },
        ],
        defaultValues: [],
    },
    {
        name: NATION_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Нация'),
        categories: [VEHICLE_CATEGORY, CREWS_CATEGORY, PERMOFLAGES_CATEGORY],
        values: [],
        defaultValues: [],
    },
    {
        name: TYPE_SHIP_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Класс техники'),
        categories: [VEHICLE_CATEGORY, PERMOFLAGES_CATEGORY],
        values: [],
        defaultValues: [],
    },
    {
        name: LEVEL_SHIP_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Уровень'),
        categories: [VEHICLE_CATEGORY, PERMOFLAGES_CATEGORY],
        values: [],
        defaultValues: [],
    },
    {
        name: DOLL_CATEGORY_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Категория'),
        categories: [DOLLS_CATEGORY],
        values: [
            {
                title: t('Символ'),
                value: NAME_SYMBOL_FILTER,
            },
            {
                title: t('Нашивка'),
                value: NAME_PATCH_FILTER,
            },
            {
                title: t('Форма'),
                value: NAME_BACKGROUND_FILTER,
            },
        ],
        defaultValues: [NAME_SYMBOL_FILTER, NAME_PATCH_FILTER, NAME_BACKGROUND_FILTER],
    },
    {
        name: CREW_CATEGORY_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Категория'),
        categories: [CREWS_CATEGORY],
        values: [
            {
                title: t('Исторические'),
                value: CREWS_CATEGORY_TYPES.HISTORY,
            },
            {
                title: t('События'),
                value: CREWS_CATEGORY_TYPES.EVENT,
            },
            {
                title: t('Особые'),
                value: CREWS_CATEGORY_TYPES.SPECIAL,
            },
        ],
        defaultValues: [CREWS_CATEGORY_TYPES.HISTORY, CREWS_CATEGORY_TYPES.EVENT, CREWS_CATEGORY_TYPES.SPECIAL],
    },
    {
        name: CREW_TYPE_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Тип'),
        categories: [CREWS_CATEGORY],
        values: [
            {
                title: t('Легендарные'),
                value: CREW_TYPES.UNIQUE,
            },
            {
                title: t('Элитные'),
                value: CREW_TYPES.ELITE,
            },
            {
                title: t('Редкие'),
                value: CREW_TYPES.ADVANCED,
            },
            {
                title: t('Особые'),
                value: CREW_TYPES.SPECIAL,
            },
        ],
        defaultValues: [CREW_TYPES.UNIQUE, CREW_TYPES.ADVANCED, CREW_TYPES.SPECIAL, CREW_TYPES.ELITE],
    },
    {
        name: ENSIGN_CATEGORY_NAME_FILTER,
        type: FILTER_CHECKBOX_TYPE,
        title: t('Категория'),
        categories: [ENSIGNS_CATEGORY],
        values: [
            {
                title: t('Наградные'),
                value: ENSIGNS_CATEGORY_TYPES.REW,
            },
            {
                title: t('Памятные'),
                value: ENSIGNS_CATEGORY_TYPES.HIS,
            },
            {
                title: t('Особые'),
                value: ENSIGNS_CATEGORY_TYPES.SPE,
            },
        ],
        defaultValues: [ENSIGNS_CATEGORY_TYPES.REW, ENSIGNS_CATEGORY_TYPES.HIS, ENSIGNS_CATEGORY_TYPES.SPE],
    },
];

const prepareNations = (filtersMap: IMaps[], response: IResponse): IMaps[] => {
    filtersMap.forEach((item) => {
        if (item.name === NATION_NAME_FILTER) {
            const nations = response.nations;
            nations.forEach((nation) => {
                item.values.push({
                    title: nation.title,
                    value: nation.name,
                    icons: nation.icons,
                });
                item.defaultValues.push(nation.name.toLowerCase());
            });
        }
    });

    return filtersMap;
};

const prepareShipTypes = (filtersMap: IMaps[], response: IResponse): IMaps[] => {
    filtersMap.forEach((item) => {
        if (item.name === TYPE_SHIP_NAME_FILTER) {
            const types = response.vehicleTypes;
            types.forEach((type) => {
                item.values.push({
                    title: type.title,
                    value: type.name,
                    icons: type.icons,
                });
                item.defaultValues.push(type.name.toLowerCase());
            });
        }
    });

    return filtersMap;
};

const prepareLevels = (filtersMap: IMaps[]): IMaps[] => {
    filtersMap.forEach((item) => {
        if (item.name === LEVEL_SHIP_NAME_FILTER) {
            for (let i = 1; i <= 11; i++) {
                item.values.push({ title: getLevelFromRomanFormat(i), value: i.toString() });
                item.defaultValues.push(i.toString());
            }
        }
    });

    return filtersMap;
};

export const prepareMaps = (response: IResponse): IMaps[] => {
    return prepareNations(prepareShipTypes(prepareLevels(filtersMap), response), response);
};

export const getFiltersByCategory = (category: ICategories): IMaps[] => {
    const maps = store.getState().ReducerApp.filtersMap;
    return maps.filter((mapItem) => !mapItem.categories || mapItem.categories.includes(category));
};

export const getFilterByKey = (key: FILTERS_NAMES): IMaps => {
    return filtersMap.filter((mapItem) => mapItem.name === key)[0];
};

export const getCheckboxFiltersByCategory = (category: ICategories): IMaps[] => {
    const items = store.getState().ReducerApp.response[category];
    const filtersByCategory = getFiltersByCategory(category).filter((mapItem) => mapItem.type === FILTER_CHECKBOX_TYPE);
    
    return filtersByCategory.reduce((newFilters, filter) => {
        filter.values = filter.values.filter((value) => {
            let show = false;

            items.forEach((i: any) => {
                if (i.filters[filter.name].includes(value.value)) {
                    show = true;
                    return;
                }
            });

            if (show) return value;
        })

        if(filter.values.length) {
            return [...newFilters, filter]
        }

        return newFilters
    }, []);
};

export const getRadioFiltersByCategory = (category: ICategories): IMaps[] => {
    return getFiltersByCategory(category).filter((mapItem) => mapItem.type === FILTER_RADIO_TYPE);
};
