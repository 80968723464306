export default {
  0: {
    x: 13,
    y: 0,
    w: 15,
    h: 22,
  },
  1: {
    x: 28,
    y: 0,
    w: 11,
    h: 22,
  },
  2: {
    x: 39,
    y: 0,
    w: 14,
    h: 22,
  },
  3: {
    x: 53,
    y: 0,
    w: 14,
    h: 22,
  },
  4: {
    x: 67,
    y: 0,
    w: 14,
    h: 22,
  },
  5: {
    x: 81,
    y: 0,
    w: 14,
    h: 22,
  },
  6: {
    x: 95,
    y: 0,
    w: 14,
    h: 22,
  },
  7: {
    x: 109,
    y: 0,
    w: 15,
    h: 22,
  },
  8: {
    x: 0,
    y: 22,
    w: 14,
    h: 22,
  },
  9: {
    x: 14,
    y: 22,
    w: 14,
    h: 22,
  },
  '-': {
    x: 0,
    y: 0,
    w: 13,
    h: 22,
  },
  A: {
    x: 28,
    y: 22,
    w: 15,
    h: 22,
  },
  B: {
    x: 43,
    y: 22,
    w: 15,
    h: 22,
  },
  C: {
    x: 58,
    y: 22,
    w: 15,
    h: 22,
  },
  D: {
    x: 73,
    y: 22,
    w: 15,
    h: 22,
  },
  E: {
    x: 88,
    y: 22,
    w: 13,
    h: 22,
  },
  F: {
    x: 101,
    y: 22,
    w: 13,
    h: 22,
  },
  G: {
    x: 114,
    y: 22,
    w: 14,
    h: 22,
  },
  H: {
    x: 0,
    y: 44,
    w: 14,
    h: 22,
  },
  I: {
    x: 14,
    y: 44,
    w: 11,
    h: 22,
  },
  J: {
    x: 25,
    y: 44,
    w: 12,
    h: 22,
  },
  K: {
    x: 37,
    y: 44,
    w: 15,
    h: 22,
  },
  L: {
    x: 52,
    y: 44,
    w: 12,
    h: 22,
  },
  M: {
    x: 64,
    y: 44,
    w: 20,
    h: 22,
  },
  N: {
    x: 84,
    y: 44,
    w: 16,
    h: 22,
  },
  O: {
    x: 100,
    y: 44,
    w: 15,
    h: 22,
  },
  P: {
    x: 0,
    y: 66,
    w: 14,
    h: 22,
  },
  Q: {
    x: 14,
    y: 66,
    w: 16,
    h: 22,
  },
  R: {
    x: 30,
    y: 66,
    w: 15,
    h: 22,
  },
  S: {
    x: 45,
    y: 66,
    w: 15,
    h: 22,
  },
  T: {
    x: 60,
    y: 66,
    w: 14,
    h: 22,
  },
  U: {
    x: 74,
    y: 66,
    w: 15,
    h: 22,
  },
  V: {
    x: 89,
    y: 66,
    w: 15,
    h: 22,
  },
  W: {
    x: 104,
    y: 66,
    w: 22,
    h: 22,
  },
  X: {
    x: 0,
    y: 88,
    w: 16,
    h: 22,
  },
  Y: {
    x: 16,
    y: 88,
    w: 16,
    h: 22,
  },
  Z: {
    x: 32,
    y: 88,
    w: 15,
    h: 22,
  },
  '[': {
    x: 47,
    y: 88,
    w: 1,
    h: 22,
  },
  ']': {
    x: 48,
    y: 88,
    w: 1,
    h: 22,
  },
  '^': {
    x: 49,
    y: 88,
    w: 56,
    h: 22,
  },
  _: {
    x: 105,
    y: 88,
    w: 16,
    h: 22,
  },
  placeholder: {
    x: 49,
    y: 88,
    w: 56,
    h: 22,
  },
};
